<template>
  <div class="l-height-full">
    <a-card title="解散房间">
      <div style="margin-bottom: 20px">
        <a-alert show-icon message="输入“房间号”或“玩家游戏ID”其中一个即可解散房间" type="warning" />
      </div>

      <div style="max-width: 600px">
        <a-row :gutter="10">
          <a-col :span="10">
            <a-form-item label="" name="roomCode">
              <a-input v-model:value="formState.roomCode" placeholder="房间号" autocomplete="off" />
            </a-form-item>
          </a-col>
          <a-col :span="1"> 或 </a-col>
          <a-col :span="10">
            <a-form-item label="" name="maskId">
              <a-input v-model:value="formState.maskId" placeholder="玩家游戏ID" autocomplete="off" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-button type="primary" @click="onDiss" :disabled="loading" :loading="loading">确认解散</a-button>
        <!-- <a-input-search v-model:value="maskId" placeholder="请输入房间内的玩家ID" @search="onDiss" /> -->
      </div>
    </a-card>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { showConfirm } from "@/common/utils";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "diss_room",
  setup() {
    const formState = reactive({
      maskId: "",
      roomCode: "",
    });

    const loading = ref(false);

    //解封
    const onDiss = () => {
      if (!formState.maskId && !formState.roomCode) {
        message.error("请输入房间号或玩家游戏ID");
        return;
      }
      showConfirm({
        title: "解散确认",
        content: "确定解散房间吗?",
        onOk: async () => {
          try {
            loading.value = true;
            await http.put(`diss_room`, formState);
            message.success("解散成功");
            loading.value = false;
          } catch (e) {
            loading.value = false;
          }
        },
      });
    };

    return {
      loading,
      formState,
      onDiss,
    };
  },
});
</script>
