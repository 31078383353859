
import http from "@/common/http";
import { showConfirm } from "@/common/utils";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "diss_room",
  setup() {
    const formState = reactive({
      maskId: "",
      roomCode: "",
    });

    const loading = ref(false);

    //解封
    const onDiss = () => {
      if (!formState.maskId && !formState.roomCode) {
        message.error("请输入房间号或玩家游戏ID");
        return;
      }
      showConfirm({
        title: "解散确认",
        content: "确定解散房间吗?",
        onOk: async () => {
          try {
            loading.value = true;
            await http.put(`diss_room`, formState);
            message.success("解散成功");
            loading.value = false;
          } catch (e) {
            loading.value = false;
          }
        },
      });
    };

    return {
      loading,
      formState,
      onDiss,
    };
  },
});
